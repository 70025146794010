import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ENV_CONFIG, EnvironmentConfig } from '../models/environment-config.model';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cacheUrls: Array<string> = [];
  private cacheData: Map<string, HttpResponse<unknown>> = new Map();

  constructor(@Inject(ENV_CONFIG) private config: EnvironmentConfig, ) {
    // List of URLs to cache
    this.cacheUrls = [
      `${config.environment.baseUrl}/api/v0/data/menu-tgif-tw.json`,
      `${config.environment.baseUrl}/api/v0/data/locations-tgif-tw.json`,
      `${config.environment.baseUrl}/api/v0/data/faq-tgif-tw.json`,
      `${config.environment.baseUrl}/api/v0/data/news-tgif-tw.json`
    ];
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Don't cache if not a GET request or if URL is not in the cache list
    if (request.method !== 'GET' || !this.cacheUrls.includes(request.url)) {
      return next.handle(request);
    }

    // Check if the URL has already been cached
    const cachedResponse: HttpResponse<unknown> = this.cacheData.get(request.urlWithParams);

    // Return cached data, or pass through current request and store the response
    if (cachedResponse) {
      return of(cachedResponse.clone());
    } else {
      return next.handle(request).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            this.cacheData.set(request.urlWithParams, event.clone());
          }
        })
      );
    }
  }
}
