// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: '',
  apiKey: 'adf62813ffe1c6b6ebdb00877b8442727743ea062fe88ef01b6b505837f8734ea6ace9e1eb5c07335db3331fcf1ab79b29d8b39daa9cb0345fe54e576c5bb722',
  previewKey: 'e278c72cc8a759e71510be446f915b3e'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
