import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebsiteLanguage } from './i18n/utils.translation';

const routes: Routes = [
  { path: WebsiteLanguage.English, loadChildren: () => import('./site/site.enUS.module').then(m => m.SiteEnUSModule) },
  { path: WebsiteLanguage.Chinese, loadChildren: () => import('./site/site.zhTW.module').then(m => m.SiteZhTWModule) },
  { path: '**', redirectTo: WebsiteLanguage.Chinese },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
