import { InjectionToken } from '@angular/core';
import { enUS } from './en-US.translation';

export enum WebsiteLanguage {
  English = 'en-US',
  Chinese = 'zh-TW',
}

export type Translation = typeof enUS;

export const TRANSLATION = new InjectionToken<Translation>('TRANSLATION');
