import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ENV_CONFIG, EnvironmentConfig } from '../models/environment-config.model';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

  constructor(@Inject(ENV_CONFIG) private config: EnvironmentConfig) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const requestWithHeaders = request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'Api-Key': this.config.environment.apiKey
      }
    });

    return next.handle(requestWithHeaders);
  }
}
